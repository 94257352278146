import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { EncrDecrService } from '../services/encr-decr-service.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private EncrDecr: EncrDecrService,) { }

  // rol = '';
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {

    if (localStorage.getItem('keylog')) {
      var decrypted = this.EncrDecr.get('123456$#@$^@1ERF', localStorage.getItem('keylog'));

      if (isNaN(decrypted) != false) {
        localStorage.clear();
      } else {
        if (localStorage.getItem('token')) {
          let modules = next.data["modules"];
          console.log('modules print', modules);
          const permisos = JSON.parse(localStorage.getItem("permisos"));
          const searchIndex = permisos.some((modulesSearch) => modulesSearch.nombre == modules)
          if (searchIndex == true) {
            return true;
          } else {
            if (modules == 'Dashboard') {
              return true;
            }
            this.router.navigate(['/login']);
            localStorage.clear();
            return false;
          }
        } else {
          this.router.navigate(['/login']);
          localStorage.clear();
          return false;
        }
      }
    } else {
      this.router.navigate(['/login']);
      return false;
    }

  }
}
