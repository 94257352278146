import { Component, OnInit, Input } from '@angular/core';
import { Articulo } from 'src/app/shared/models/articulo';

@Component({
  selector: 'vex-almacenes-print-articulos',
  templateUrl: './print-articulos.component.html',
  styleUrls: ['./print-articulos.component.scss']
})
export class PrintArticulosComponent implements OnInit {
  @Input() nombreAlmacen: string = "";
  @Input() articulos: Array<Articulo> = [];

  constructor() { }

  ngOnInit(): void {
  }

}
