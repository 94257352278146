import { Injectable } from '@angular/core';
import { io } from "socket.io-client";
import { BehaviorSubject, Observable, ReplaySubject, Subject, map, of, tap } from 'rxjs'
import { DataResponseNotification } from '../shared/models/response/data-response-notification';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SocketioService {
  socket: any;
  readonly uri = environment.apiConfig.pathSocket;
  _notificationsCount$: BehaviorSubject<number>;

  constructor() {
    this.socket = io(this.uri);
    this._notificationsCount$ = new BehaviorSubject<number>(0);
  }

  get notificationsCount$(): Observable<number> {
    return this._notificationsCount$.asObservable();
  }

  lisent(eventName: String): Observable<DataResponseNotification> {
    return new Observable<DataResponseNotification>( (subscriber) => {
      this.socket.on(eventName, (data: DataResponseNotification) => {
        subscriber.next(data);
        this._notificationsCount$.next(data.notificationsCount);
      });
    });
  }

  emmit(eventName: String, data: any) {
    this.socket.emmit(eventName, data);
  }
}