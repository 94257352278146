import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PrintHelperService {

  constructor() { }
  
  /**
   * captura todos los tags tipo link
   * @param tagName
   * @return string tag type style
   */
  private getTagsHtml(tagName: keyof HTMLElementTagNameMap): string {
    const htmlStr: string[] = [];
    const elements = document.getElementsByTagName(tagName);
    for (let idx: number = 0; idx < elements.length; idx++) {
      htmlStr.push(elements[idx].outerHTML);
    }
    return htmlStr.join('\r\n');
  }

  /**
   * muestra una ventana emergente con 
   * la pantalla print abierta.
   * @param contenido 
   */
  public printInWindow(contenido: string): void {
    const printContents = document.getElementById(contenido).innerHTML;
    const popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto,fullscreen=yes,menubar=0');
    popupWin.document.open();
    const stylesHtml = this.getTagsHtml('style');
    popupWin.document.write(`
      <html>
        <head>
          <title>Print tab</title>
          ${stylesHtml}
        </head>
        <script>
          function imprimir(){
            window.print();
            setTimeout(function(){
              window.close();
            }, 500);
          }
        </script>
        <body onload = "imprimir()">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
  }
}
