<form [formGroup]="form" *ngIf="modeStyle == ''">
    <div class="flex items-center" mat-dialog-title>

        <h2 *ngIf="isCreateMode()" class="headline m-0 flex-auto">Añadir almacén</h2>
        <h2 *ngIf="isUpdateMode()" class="headline m-0 flex-auto">Editar almacén</h2>

        <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
            <mat-icon svgIcon="mat:close"></mat-icon>
        </button>
    </div>

    <mat-divider class="-mx-6 text-border"></mat-divider>

    <mat-dialog-content fxLayout="column">

        <ng-container *ngIf="isCreateMode()">
            <div class="flex flex-col sm:flex-row">
                <mat-form-field class="mt-6 flex-auto">
                    <mat-label>Ubicación</mat-label>
                    <input cdkFocusInitial formControlName="direccion" matInput
                        (keydown.enter)="$event.preventDefault()" #search (keyup)="onKeyUpEvent($event)">
                    <mat-icon (click)="change()" class="mr-3" matPrefix svgIcon="mat:location_on"
                        matTooltip="Ubicación precisa"></mat-icon>

                </mat-form-field>
            </div>
            <google-map #myGoogleMap *ngIf="showMap == true" width="100%" height="300px" [zoom]="zoom"
                [options]="options" [center]="center" (mapClick)="eventHandler($event)">
                <map-marker #marker="mapMarker" *ngFor="let markerPosition of markers" [position]="markerPosition">
                </map-marker>
            </google-map>


            <div class="grid grid-cols-3 gap-4 mt-4">
                <mat-form-field appearance="outline">
                    <mat-label>Dirección</mat-label>
                    <input cdkFocusInitial formControlName="direccion_string" matInput>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Latitud</mat-label>
                    <input cdkFocusInitial formControlName="lat" matInput>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Longitud</mat-label>
                    <input cdkFocusInitial formControlName="lng" matInput>
                </mat-form-field>
            </div>
        </ng-container>

        <div class="flex flex-col sm:flex-row">
            <mat-form-field appearance="outline" class="mt-6 flex-auto">
                <mat-label>Nombre</mat-label>
                <input cdkFocusInitial formControlName="nombre" matInput>
            </mat-form-field>

            <mat-form-field appearance="outline" class="sm:mt-6 sm:ml-6 flex-auto">
                <mat-label>Descripción</mat-label>
                <input cdkFocusInitial formControlName="descripcion" matInput>
            </mat-form-field>
        </div>

        <div class="flex flex-col sm:flex-row">
            <mat-form-field appearance="outline" class="mt-6 flex-auto">
                <mat-label>Tipo</mat-label>
                <mat-select formControlName="tipo">
                    <mat-option [value]="1">Joyas</mat-option>
                    <mat-option [value]="2">Autos</mat-option>
                    <mat-option [value]="3">Propiedades</mat-option>
                    <mat-option [value]="4">En sucursal</mat-option>
                    <mat-option [value]="5">Temporal</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" class="sm:mt-6 sm:ml-6 flex-auto">
                <mat-label>Sucursal</mat-label>
                <mat-select formControlName="sucursal">
                    <mat-option [value]="item.id" *ngFor="let item of sucrusales">{{item.nombre}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close type="button">CANCEL</button>
        <button *ngIf="isCreateMode()" class="btn-block" adiButton [loading]="loading[2]" (click)="add(2)"
            cdkFocusInitial>
            Guardar
            <ng-template adiButtonLoader> Guardando... </ng-template>
        </button>
        <button *ngIf="isUpdateMode()" class="btn-block" adiButton [loading]="loading[2]" (click)="edit(2)"
            cdkFocusInitial>
            Editar
            <ng-template adiButtonLoader> Editando... </ng-template>
        </button>
    </mat-dialog-actions>
</form>

<div *ngIf="modeStyle == 'delAlmacen'">
    <div class="flex items-center justify-between" mat-dialog-title>

        <h2 class="headline m-0 flex-auto">Alerta</h2>

        <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
            <mat-icon svgIcon="mat:close"></mat-icon>
        </button>
    </div>

    <mat-dialog-content>
        <p>Eliminar almacén</p>
    </mat-dialog-content>


    <mat-dialog-actions align="end">
        <button style="margin-right: 10px;" mat-button mat-dialog-close>No</button>
        <button adiButton [loading]="loading[1]" variant="primary" (click)="close('Si', 1)">Si</button>
    </mat-dialog-actions>
</div>

<div *ngIf="modeStyle == 'articulos'">
    <div class="flex items-center" mat-dialog-title>

        <h2 class="headline m-0 flex-auto">Artículos en almacén
        </h2>

        <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
            <mat-icon svgIcon="mat:close"></mat-icon>
        </button>
    </div>

    <mat-divider class="-mx-6 text-border"></mat-divider>

    <mat-dialog-content fxLayout="column">

        <div class="flex flex-col items-center">
            <mat-form-field appearance="outline" class="mt-6 w-2/3 sm:w-auto">
                <mat-label>Buscar...
                </mat-label>
                <input matInput (input)="onSearchChange($event)">
            </mat-form-field>

            <button type="button" matButton (click)="printArticulos()">
                <mat-icon svgIcon="mat:print"></mat-icon>
                Imprimir
            </button>
        </div>
        <div class="container mx-auto px-4 py-8">
            <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                <div *ngFor="let item of articulos"
                    class="bg-white shadow-lg rounded-lg p-4 hover:shadow-lg transition duration-300 ease-in-out flex flex-col justify-center items-center">
                    <div class="rounded-full bg-gray-300 h-20 w-20 mx-auto mb-4 overflow-hidden">
                        <img [src]="item.src_foto_articulo" *ngIf="item.src_foto_articulo" alt="Artículo"
                            class="object-cover h-full w-full" />
                        <img src="../../../../../assets/null.jpg" *ngIf="!item.src_foto_articulo"
                            class="object-cover h-full w-full" />
                    </div>
                    <h2 class="text-lg font-semibold mb-2 text-center colorPrimary">{{item.nombre_articulo}}</h2>
                    <div class="rounded-full bg-gray-500 text-white px-2 py-1 text-xs mb-2">{{item.codigo_cuadernillo}}
                    </div>
                    <div class="relative h-8">
                        <button class="popover-btn inline-block max-w-xs truncate" (click)="togglePopover(1)">
                            <mat-icon svgIcon="mat:info"></mat-icon>
                        </button>
                        <div class="popover bg-gray-900 text-white text-xs py-1 px-2 rounded-lg absolute bottom-0 left-0 transform translate-x-1/2 -translate-y-full"
                            [class.hidden]="!showPopover[1]">
                            {{item.categoria_nombre}}
                        </div>
                    </div>
                    <button (click)="detailArticulo(item)"
                        class="mt-auto w-full px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 flex items-center justify-center">
                        <mat-icon svgIcon="mat:visibility"></mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </mat-dialog-content>
</div>

<div *ngIf="modeStyle == 'createDetalle'">
    <form [formGroup]="form">
        <div class="flex items-center" mat-dialog-title>

            <h2 class="headline m-0 flex-auto">Crear movimiento</h2>

            <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
                <mat-icon svgIcon="mat:close"></mat-icon>
            </button>
        </div>

        <mat-divider class="-mx-6 text-border"></mat-divider>

        <mat-dialog-content fxLayout="column">

            <div class="flex flex-col sm:flex-row">
                <mat-form-field appearance="outline" class="mt-6 flex-auto">
                    <mat-label>Descripción</mat-label>
                    <input cdkFocusInitial formControlName="descripcion" matInput>
                </mat-form-field>
            </div>
            <div class="flex flex-col sm:flex-row">
                 <mat-form-field appearance="outline" class="mt-6 flex-auto">
                    <mat-label>Tipo</mat-label>
                    <mat-select formControlName="tipo">
                        <mat-option [value]="2"> Ingreso </mat-option>
                        <mat-option [value]="3"> Egreso </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

        </mat-dialog-content>

        <mat-dialog-actions align="end">
            <button mat-button mat-dialog-close type="button">CANCEL</button>
            <button *ngIf="defaults.mode == 'createDetalle'" class="btn-block" adiButton [loading]="loading[2]"
                (click)="addCuenta(2)" cdkFocusInitial>
                Guardar
                <ng-template adiButtonLoader> Guardando... </ng-template>
            </button>
            <button *ngIf="defaults.mode == 'editDetalle'" class="btn-block" adiButton [loading]="loading[2]"
                (click)="editCuenta(2)" cdkFocusInitial>
                Editar
                <ng-template adiButtonLoader> Editando... </ng-template>
            </button>
        </mat-dialog-actions>
    </form>
</div>

<div *ngIf="modeStyle == 'deleteDetalle'">
    <div class="flex items-center justify-between" mat-dialog-title>

        <h2 class="headline m-0 flex-auto">Alerta</h2>

        <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
            <mat-icon svgIcon="mat:close"></mat-icon>
        </button>
    </div>

    <mat-dialog-content>
        <p>Eliminar movimiento</p>
    </mat-dialog-content>


    <mat-dialog-actions align="end">
        <button style="margin-right: 10px;" mat-button mat-dialog-close>No</button>
        <button adiButton [loading]="loading[1]" variant="primary" (click)="close2('Si', 1)">Si</button>
    </mat-dialog-actions>
</div>

<div *ngIf="modeStyle == 'createConcepto'">
    <form [formGroup]="form">
        <div class="flex items-center" mat-dialog-title>

            <h2 class="headline m-0 flex-auto">Crear concepto</h2>

            <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
                <mat-icon svgIcon="mat:close"></mat-icon>
            </button>
        </div>

        <mat-divider class="-mx-6 text-border"></mat-divider>

        <mat-dialog-content fxLayout="column">

            <div class="flex flex-col sm:flex-row">
                <mat-form-field appearance="outline" class="mt-6 flex-auto">
                    <mat-label>Descripción</mat-label>
                    <input cdkFocusInitial formControlName="descripcion" matInput>
                </mat-form-field>
            </div>

        </mat-dialog-content>

        <mat-dialog-actions align="end">
            <button mat-button mat-dialog-close type="button">CANCEL</button>
            <button *ngIf="defaults.mode == 'createConcepto'" class="btn-block" adiButton [loading]="loading[2]"
                (click)="addConcepto(2)" cdkFocusInitial>
                Guardar
                <ng-template adiButtonLoader> Guardando... </ng-template>
            </button>
            <button *ngIf="defaults.mode == 'editConcepto'" class="btn-block" adiButton [loading]="loading[2]"
                (click)="editConcepto(2)" cdkFocusInitial>
                Editar
                <ng-template adiButtonLoader> Editando... </ng-template>
            </button>
        </mat-dialog-actions>
    </form>
</div>

<div *ngIf="modeStyle == 'deleteConcepto'">
    <div class="flex items-center justify-between" mat-dialog-title>

        <h2 class="headline m-0 flex-auto">Alerta</h2>

        <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
            <mat-icon svgIcon="mat:close"></mat-icon>
        </button>
    </div>

    <mat-dialog-content>
        <p>Eliminar concepto</p>
    </mat-dialog-content>


    <mat-dialog-actions align="end">
        <button style="margin-right: 10px;" mat-button mat-dialog-close>No</button>
        <button adiButton [loading]="loading[1]" variant="primary" (click)="close3('Si', 1)">Si</button>
    </mat-dialog-actions>
</div>

<vex-almacenes-print-articulos #reporte id="reporteAlmacenArticulos" class="visible-print" [nombreAlmacen]="defaults.nombre" [articulos]="articulos"></vex-almacenes-print-articulos>