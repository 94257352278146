import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MenuItem } from '../interfaces/menu-item.interface';
import { trackById } from '../../../../utils/track-by';
import { PopoverRef } from '../../../../components/popover/popover-ref';
import { SerService } from 'src/app/services/ser.service';
import { MatDialog } from '@angular/material/dialog';
import { EmpCreateUpdateComponent } from 'src/app/pages/apps/empleados/emp-create-update/emp-create-update.component';

export interface OnlineStatus {
  id: 'online' | 'away' | 'dnd' | 'offline';
  label: string;
  icon: string;
  colorClass: string;
}

@Component({
  selector: 'vex-toolbar-user-dropdown',
  templateUrl: './toolbar-user-dropdown.component.html',
  styleUrls: ['./toolbar-user-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarUserDropdownComponent implements OnInit {

  items: MenuItem[] = [
    {
      id: '1',
      icon: 'mat:account_circle',
      label: 'My Profile',
      description: 'Personal Information',
      colorClass: 'text-teal',
      route: '/apps/social'
    },
    {
      id: '2',
      icon: 'mat:move_to_inbox',
      label: 'My Inbox',
      description: 'Messages & Latest News',
      colorClass: 'text-primary',
      route: '/apps/chat'
    },
    {
      id: '3',
      icon: 'mat:list_alt',
      label: 'My Projects',
      description: 'Tasks & Active Projects',
      colorClass: 'text-amber',
      route: '/apps/scrumboard'
    },
    {
      id: '4',
      icon: 'mat:table_chart',
      label: 'Billing Information',
      description: 'Pricing & Current Plan',
      colorClass: 'text-purple',
      route: '/pages/pricing'
    }
  ];

  statuses: OnlineStatus[] = [
    {
      id: 'online',
      label: 'Online',
      icon: 'mat:check_circle',
      colorClass: 'text-green'
    },
    {
      id: 'away',
      label: 'Away',
      icon: 'mat:access_time',
      colorClass: 'text-orange'
    },
    {
      id: 'dnd',
      label: 'Do not disturb',
      icon: 'mat:do_not_disturb',
      colorClass: 'text-red'
    },
    {
      id: 'offline',
      label: 'Offline',
      icon: 'mat:offline_bolt',
      colorClass: 'text-gray'
    }
  ];

  activeStatus: OnlineStatus = this.statuses[0];

  trackById = trackById;
  nombre = '';
  constructor(private cd: ChangeDetectorRef,
    private popoverRef: PopoverRef<ToolbarUserDropdownComponent>,
    public service: SerService,
    private dialog: MatDialog,
  ) {
    this.nombre = localStorage.getItem('name');
  }

  ngOnInit() {
    this.getSucursales();
    this.local.push({
      nombre_sucursal: localStorage.getItem('nombre_sucursal'),
      color_sucursal: localStorage.getItem('color_sucursal'),
      id_sucursal: localStorage.getItem('sucursal'),
    })
  }

  sucursales = [];
  local = [];
  getSucursales() {
    this.service.getSucursales().subscribe((data) => {
      console.log(data.data);
      this.sucursales = data.data;
      this.cd.detectChanges();
    });
  }

  setStatus(data) {
    this.cd.markForCheck();
    this.local = [];
    this.local.push({
      nombre_sucursal: data.nombre,
      color_sucursal: data.color,
      id_sucursal: data.id,
    })
    //localstorage
    localStorage.removeItem('sucursal');
    localStorage.removeItem('nombre_sucursal');
    localStorage.removeItem('color_sucursal');

    localStorage.setItem('sucursal', data.id);
    localStorage.setItem('nombre_sucursal', data.nombre);
    localStorage.setItem('color_sucursal', data.color);

    window.location.reload();
  }

  close() {
    localStorage.clear();
    this.popoverRef.close();
  }

  editPerfil(): void {
    this.dialog.open(EmpCreateUpdateComponent, {
      data: {
        mode: 'editPerfil'
      },
      width: '450px',
    });
  }
}
