import { Injectable } from '@angular/core';
import { Observable, throwError, of } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';
import { EncrDecrService } from './encr-decr-service.service';
import { environment } from 'src/environments/environment';
import { Articulo } from '../shared/models/articulo';
import { DataResponse } from '../shared/models/response/data-response';
import { CategoriaService } from '../shared/services/categoria.service';

const httpHeaders = {
  headers: new HttpHeaders(environment.apiConfig.headersFormData),
  reportProgress: true
}
const path = environment.apiConfig.path;
@Injectable({
  providedIn: 'root'
})

@Injectable({
  providedIn: 'root'
})
export class SerService {
  httpOptions: { headers: HttpHeaders; };
  urls: string;
  token: string;


  constructor(private http: HttpClient, 
    private EncrDecr: EncrDecrService,
    private categoriaService: CategoriaService) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + this.token
      }),
    };
  }

  getSucursales(): Observable<any> {
    this.token = localStorage.getItem('token');
    this.urls = path + "/sucursal";
    return this.http.get(this.urls, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  createSucursal(nombre, departamento, telefonos, provincia, direccion, lat, foto, lng, nit, estado, horario, color, abreviacion): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.token
      }),
    };
    var formData: any = new FormData();
    formData.append("nombre", nombre);
    formData.append("departamento", departamento);
    formData.append("telefonos", telefonos);
    formData.append("provincia", provincia);
    formData.append("direccion", direccion);
    formData.append("foto", foto);
    formData.append("lat", lat);
    formData.append("lng", lng);
    formData.append("nit", nit);
    formData.append("estado", estado);
    formData.append("horario", horario);
    formData.append("abreviacion", abreviacion);
    formData.append("color", color);
    this.urls = path + "/sucursal";
    return this.http.post(this.urls, formData, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  editSucursal1(id, nombre, telefonos, foto, nit, estado, horario, color, abreviacion): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.token
      }),
    };
    var formData: any = new FormData();
    formData.append("nombre", nombre);
    formData.append("telefonos", telefonos);
    formData.append("foto", foto);
    formData.append("nit", nit);
    formData.append("estado", estado);
    formData.append("horario", horario);
    formData.append("color", color);
    formData.append("abreviacion", abreviacion);
    formData.append("_method", "put");
    this.urls = path + "/sucursal/" + id;
    return this.http.post(this.urls, formData, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  editSucursal2(id, departamento, provincia, direccion, lat, lng): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.token
      }),
    };
    var formData: any = new FormData();
    formData.append("departamento", departamento);
    formData.append("provincia", provincia);
    formData.append("direccion", direccion);
    formData.append("lat", lat);
    formData.append("lng", lng);
    formData.append("_method", "put");
    this.urls = path + "/sucursal/" + id;
    return this.http.post(this.urls, formData, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  deleteSucursal(id): Observable<any> {
    this.urls = path + "/sucursal/" + id;
    return this.http.delete(this.urls, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  getEmpleados(): Observable<any> {
    this.urls = path + "/empleados";
    return this.http.get(this.urls, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  createEmpleado(nombre_completo, fecha, cargo, fecha_salida, turno, telefono, celular, direccion, foto, sucursal_id, email, password, fotoCi1, fotoCi2,
    lat, lng): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.token
      }),
    };
    var formData: any = new FormData();
    formData.append("nombre_completo", nombre_completo);
    formData.append("fecha", fecha);
    formData.append("cargo", cargo);
    formData.append("fecha_salida", fecha_salida);
    formData.append("turno", turno);
    formData.append("telefono", telefono);
    formData.append("celular", celular);
    formData.append("direccion", direccion);
    formData.append("foto", foto);
    formData.append("sucursales_id", sucursal_id);
    formData.append("lat", lat);
    formData.append("lng", lng);

    formData.append("email", email);
    formData.append("password", password);
    formData.append("src_foto_ci_front", fotoCi1);
    formData.append("src_foto_ci_back", fotoCi2);


    this.urls = path + "/empleados";
    return this.http.post(this.urls, formData, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  editEmpleado(id, nombre_completo, fecha, cargo, fecha_ingreso, fecha_salida, turno, telefono, celular, direccion, foto, fotoCi1, fotoCi2): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.token
      }),
    };
    var formData: any = new FormData();
    formData.append("nombre_completo", nombre_completo);
    formData.append("fecha", fecha);
    formData.append("cargo", cargo);
    formData.append("fecha_ingreso", fecha_ingreso);
    formData.append("fecha_salida", fecha_salida);
    formData.append("turno", turno);
    formData.append("telefono", telefono);
    formData.append("celular", celular);
    formData.append("direccion", direccion);
    formData.append("foto", foto);
    formData.append("src_foto_ci_front", fotoCi1);
    formData.append("src_foto_ci_back", fotoCi2);
    formData.append("_method", "put");
    this.urls = path + "/empleados/" + id;
    return this.http.post(this.urls, formData, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  editEmpleadoSucursal(id, sucursal_id): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.token
      }),
    };
    var formData: any = new FormData();
    formData.append("sucursales_id", sucursal_id);
    formData.append("_method", "put");
    this.urls = path + "/empleados/" + id;
    return this.http.post(this.urls, formData, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  editDireccionEmpleado(id, lat, lng): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.token
      }),
    };
    var formData: any = new FormData();
    formData.append("lat", lat);
    formData.append("lng", lng);
    formData.append("_method", "put");
    this.urls = path + "/empleados/" + id;
    return this.http.post(this.urls, formData, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  deleteEmpleado(id): Observable<any> {
    this.urls = path + "/empleados/" + id;
    return this.http.delete(this.urls, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  getFormaciones(empleados_id): Observable<any> {
    this.urls = path + "/formacion-emp";

    var datoaEnviar = {
      "empleados_id": empleados_id,
    }
    return this.http.post(this.urls, datoaEnviar, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  createFormaciones(nombre, nivel, ano_egreso, institucion, empleados_id): Observable<any> {
    this.urls = path + "/formacion-create";

    var datoaEnviar = {
      "nombre": nombre,
      "nivel": nivel,
      "ano_egreso": ano_egreso,
      "institucion": institucion,
      "empleados_id": empleados_id,
    }
    return this.http.post(this.urls, datoaEnviar, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  editFormaciones(id, nivel, ano_egreso, institucion, nombre): Observable<any> {
    this.urls = path + "/formacion-edit/" + id;

    var datoaEnviar = {
      "nombre": nombre,
      "nivel": nivel,
      "ano_egreso": ano_egreso,
      "institucion": institucion,
    }
    return this.http.put(this.urls, datoaEnviar, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  deleteFormacion(id): Observable<any> {
    this.urls = path + "/formacion-delete/" + id;
    return this.http.delete(this.urls, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  getRoles(): Observable<any> {
    this.urls = path + "/roles";

    return this.http.get(this.urls, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  createRol(nombre, descripcion): Observable<any> {
    this.urls = path + "/rol-create";

    var datoaEnviar = {
      "nombre": nombre,
      "descripcion": descripcion,
    }
    return this.http.post(this.urls, datoaEnviar, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  editRol(id, nombre, descripcion): Observable<any> {
    this.urls = path + "/rol-edit/" + id;

    var datoaEnviar = {
      "nombre": nombre,
      "descripcion": descripcion,
    }
    return this.http.put(this.urls, datoaEnviar, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  delRol(id): Observable<any> {
    this.urls = path + "/rol-delete/" + id;
    return this.http.delete(this.urls, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  getPermiso(): Observable<any> {
    this.urls = path + "/permisos";

    return this.http.get(this.urls, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  createPermiso(nombre): Observable<any> {
    this.urls = path + "/permisos";

    var datoaEnviar = {
      "nombre": nombre,
    }
    return this.http.post(this.urls, datoaEnviar, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  editPermiso(id, nombre): Observable<any> {
    this.urls = path + "/permisos/" + id;

    var datoaEnviar = {
      "nombre": nombre,
    }
    return this.http.put(this.urls, datoaEnviar, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  delPermiso(id): Observable<any> {
    this.urls = path + "/permisos/" + id;
    return this.http.delete(this.urls, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  asignar(rol_id, permisos): Observable<any> {
    this.urls = path + "/permisorol";

    var datoaEnviar = {
      "rol_id": rol_id,
      "permisos": permisos,
    }
    return this.http.post(this.urls, datoaEnviar, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  editarAsigncacion(rol_id, permisos, borrados): Observable<any> {
    this.urls = path + "/edit-permisorol";

    var datoaEnviar = {
      "rol_id": rol_id,
      "permisos": permisos,
      "borrados": borrados,
    }
    return this.http.post(this.urls, datoaEnviar, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  editAccesos(id, email, password): Observable<any> {
    this.urls = path + "/edit-user/" + id;

    var datoaEnviar = {
      "email": email,
      "password": password,
    }
    return this.http.put(this.urls, datoaEnviar, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  asignarUserRol(users_id, roles_id): Observable<any> {
    this.urls = path + "/asign-user";

    var datoaEnviar = {
      "users_id": users_id,
      "roles_id": roles_id,
    }
    return this.http.post(this.urls, datoaEnviar, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  getCategorias(): Observable<any> {
    return this.categoriaService.getAll();
  }

  createCategoria(nombre, descripcion): Observable<any> {
    this.urls = path + "/categorias";

    var datoaEnviar = {
      "nombre": nombre,
      "descripcion": descripcion,
    }
    return this.http.post(this.urls, datoaEnviar, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  editCategoria(id, nombre, descripcion): Observable<any> {
    this.urls = path + "/categorias/" + id;

    var datoaEnviar = {
      "nombre": nombre,
      "descripcion": descripcion,
    }
    return this.http.put(this.urls, datoaEnviar, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  delCategoria(id): Observable<any> {
    this.urls = path + "/categorias/" + id;
    return this.http.delete(this.urls, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  getSubs(categoria_id): Observable<any> {
    this.urls = path + "/subcategorias";

    var datoaEnviar = {
      "categoria_id": categoria_id,
    }

    return this.http.post(this.urls, datoaEnviar, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  createSubs(nombre, descripcion, foto, categoria_id): Observable<any> {
    this.urls = path + "/sub-create";
    this.httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.token
      }),
    };
    var formData: any = new FormData();
    formData.append("nombre", nombre);
    formData.append("descripcion", descripcion);
    formData.append("categoria_id", categoria_id);
    formData.append("foto", foto);

    return this.http.post(this.urls, formData, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  editSubs(id, nombre, descripcion, foto): Observable<any> {
    this.urls = path + "/sub-edit/" + id;
    this.httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.token
      }),
    };
    var formData: any = new FormData();
    formData.append("nombre", nombre);
    formData.append("descripcion", descripcion);
    formData.append("foto", foto);
    formData.append("_method", "put");

    return this.http.post(this.urls, formData, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  delSubs(id): Observable<any> {
    this.urls = path + "/sub-delete/" + id;
    return this.http.delete(this.urls, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  getCreditos(filterSearch, page: number, perPage: number, fecha_inicio, fecha_fin): Observable<DataResponse> {
    let params = new HttpParams();
    params = params.append('page', page);
    params = params.append('perpage', perPage);
    params = params.append('nombre', filterSearch);
    params = params.append('fecha_inicio', fecha_inicio);
    params = params.append('fecha_fin', fecha_fin);

    return this.http.get<DataResponse>(`${path}/prestamos`, {
      headers: new HttpHeaders(environment.apiConfig.headers),
      reportProgress: true,
      params: params
    });
  }

  getClientes(): Observable<any> {
    this.urls = path + "/clientes-get";

    return this.http.get(this.urls, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  createPrestamo(comentario, cr, cliente_id, empleado_id, sucursal_id, tipo_credito_id, formato, firma, articulos, cuotas
  ): Observable<any> {
    this.urls = path + "/prestamos";

    var datoaEnviar = {
      "comentario": comentario,
      "cr": cr,
      "cliente_id": cliente_id,
      "empleado_id": empleado_id,
      "sucursal_id": sucursal_id,
      "tipo_credito_id": tipo_credito_id,
      "duracion": formato,
      "firma": firma,
      "articulos": articulos,
      "formato": cuotas,
    }
    return this.http.post(this.urls, datoaEnviar, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  verPrestamo(id): Observable<any> {
    this.urls = path + "/prestamos/" + id;

    return this.http.get(this.urls, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  editPrestamo(comentario, interes, taza_conservacion, capital, cr, id): Observable<any> {
    this.urls = path + "/prestamos/" + id;

    var datoaEnviar = {
      "comentario": comentario,
      "interes": interes,
      "taza_conservacion": taza_conservacion,
      "capital": capital,
      "cr": cr,
    }
    return this.http.put(this.urls, datoaEnviar, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  delPrestamo(id): Observable<any> {
    this.urls = path + "/prestamos/" + id;
    return this.http.delete(this.urls, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  prorroga(contrato_articulo_id): Observable<any> {
    this.urls = path + "/cuotas/prorroga/create";
    var datoaEnviar = {
      'contrato_articulo_id': contrato_articulo_id
    }
    return this.http.post(this.urls, datoaEnviar, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  obtenerTotalPagar(contrato_articulo_id): Observable<any> {
    this.urls = path + "/pagos/capital/obtener-total-pagar";
    var datoaEnviar = {
      'contrato_articulo_id': contrato_articulo_id
    }
    return this.http.post(this.urls, datoaEnviar, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  pagarTotalPagar(cuota_id, nuevo_interes, total_pagar, tipo_pago, detalle_tipo_pago, sucursal_id): Observable<any> {
    this.urls = path + "/pagos/pago-total-capital";
    var datoaEnviar = {
      'cuota_id': cuota_id,
      'nuevo_interes': nuevo_interes,
      'total_pagar': total_pagar,
      'tipo_pago': tipo_pago,
      'detalle_tipo_pago': detalle_tipo_pago,
      "sucursal_id": sucursal_id,
    }
    return this.http.post(this.urls, datoaEnviar, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  almacen_sucursal(sucursal_id): Observable<any> {
    this.urls = path + "/almacenes/almacen-sucursal";
    var datoaEnviar = {
      "sucursal_id": sucursal_id,
    }
    return this.http.post(this.urls, datoaEnviar, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  getRef(id): Observable<any> {
    this.urls = path + "/refinanciamientos/build-doc/" + id;

    return this.http.get(this.urls, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

}
