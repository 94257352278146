import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, NgZone, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { SvgIconOverrides } from '@thouet/material-carousel';
import { PopoverService } from 'src/@vex/components/popover/popover.service';
import { SerService } from 'src/app/services/ser.service';
import { Ser2Service } from 'src/app/services/ser2.service';

@Component({
  selector: 'vex-articulo-create-update',
  templateUrl: './articulo-create-update.component.html',
  styleUrls: ['./articulo-create-update.component.scss']
})
export class ArticuloCreateUpdateComponent implements OnInit {
  modeStyle = '';
  loading = { 1: false, 2: false, 3: false, 4: false };
  form: FormGroup;
  icons: SvgIconOverrides = {
    arrowBack: 'arrow_back',
    arrowForward: 'arrow_forward'
  };
  fotos: any = [];
  idSucursal;

  constructor(
    @Inject(MAT_DIALOG_DATA) public defaults: any,
    private dialogRef: MatDialogRef<ArticuloCreateUpdateComponent>,
    private fb: FormBuilder,
    private snackbar: MatSnackBar,
    private ngZone: NgZone,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef,
    public service2: Ser2Service,
    public service: SerService,
    private popoverService: PopoverService,
    public iconRegistry: MatIconRegistry,
    public sanitizer: DomSanitizer,
    private _bottomSheet: MatBottomSheet,
    public datepipe: DatePipe,
  ) { }

  ngOnInit(): void {
    this.idSucursal = parseInt(localStorage.getItem('sucursal'));
    console.log(this.defaults);

    if (this.defaults && this.defaults.mode == 'detalleArticulo') {
      console.log(this.defaults);
      this.fotos = this.defaults.data.fotos;

      if (this.defaults.mode == 'detalleArticulo') {
        this.modeStyle = 'detalleArticulo';
        this.fotos = this.defaults.data.fotos;
        this.iconRegistry.addSvgIcon(
          'arrow_back',
          this.sanitizer.bypassSecurityTrustResourceUrl('assets/img/icons/arrow_back.svg')
        );

        this.iconRegistry.addSvgIcon(
          'arrow_forward',
          this.sanitizer.bypassSecurityTrustResourceUrl('assets/img/icons/arrow_forward.svg')
        );
      }
    } else if (this.defaults && this.defaults.mode == 'detalleArticulo2') {
      if (this.defaults.mode == 'detalleArticulo2') {
        this.modeStyle = 'detalleArticulo2';

        this.fotos = this.defaults.data.articulo.fotos;
        this.iconRegistry.addSvgIcon(
          'arrow_back',
          this.sanitizer.bypassSecurityTrustResourceUrl('assets/img/icons/arrow_back.svg')
        );

        this.iconRegistry.addSvgIcon(
          'arrow_forward',
          this.sanitizer.bypassSecurityTrustResourceUrl('assets/img/icons/arrow_forward.svg')
        );
      }
    }

    if (this.defaults.mode == 'multiple_registro') {
      this.modeStyle = 'multiple_registro';
      this.getProductosPendientes();
      this.getSucursal();
      const hora = new Date().getHours();
      const min = new Date().getMinutes();
      this.form = this.fb.group({
        sucursal_id: ['', Validators.required],
        almacen_destiono: ['', Validators.required],
        fecha: [new Date, Validators.required],
        hora: [hora + ':' + min, Validators.required],
        precauciones: ['', Validators.required],
        observaciones: ['', Validators.required],
      })
    }
  }

  pendientes;
  getProductosPendientes() {
    this.service2.traslados_pendientes_p(this.idSucursal).subscribe((data) => {
      console.log(data);
      this.pendientes = data;
    });
  }

  detailArticulo(data): void {
    this.dialog.open(ArticuloCreateUpdateComponent, {
      data: {
        mode: 'detalleArticulo', data: data
      },
      width: '800px',
    });
  }

  sucursalId;
  sucursales = [];
  getSucursal() {
    this.service.getSucursales().subscribe((data) => {
      let dataResouce = data.data;
      this.sucursales = dataResouce? dataResouce.filter(item=>item.id != localStorage.getItem('sucursal')): [];

    });
  }

  selectSucursal(data) {
    this.sucursalId = data.id;
    this.getAlmacenSucursal();
  }

  almacenes = [];
  getAlmacenSucursal() {
    this.service.almacen_sucursal(this.sucursalId).subscribe((data) => {
      this.almacenes = data.data;
    });
  }

  arrayArtiuclos: any[] = [];
  changeArticulos(e, articuls): void {
    if (e.checked == true) {
      this.arrayArtiuclos.push({
        "id": articuls.id,
        "almacen_id_origen": this.form.value.sucursal_id,
        "almacen_id_destino": this.form.value.almacen_destiono,
        "contrato_articulo_id": articuls.id_contratoArticulo,
        "fecha": this.datepipe.transform(this.form.value.fecha, 'yyyy-MM-dd'),
        "hora": this.form.value.hora,
        "precauciones": this.form.value.precauciones,
        "observacion": this.form.value.observaciones,
        "sucursal_id_origen": localStorage.getItem('sucursal'),
      });
    } else {
      var index = this.arrayArtiuclos.map(function (e) {
        return e.id;
      }).indexOf(articuls.id);
      this.arrayArtiuclos.splice(index, 1);
    }
  }

  add(i) {
    this.loading[i] = true;
    if (this.form.valid) {
      this.service2.solicitud_traslado_multiple(this.arrayArtiuclos).subscribe({
        next: (data) => {
          this.message('Registrado');
          const customer = this.form.value;
          this.dialogRef.close(customer);
        }, 
        error: err => {
          this.loading[i] = false;
          this.message(err.error.message);
        }
      });
    } else {
      this.loading[i] = false;
      this.message('Please check the data');
    }
  }

  message(m): void {
    this.snackbar.open(m, 'Cerrar', {
      duration: 4000
    });
  }
}
