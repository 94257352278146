<h3 class="text-center">
    Reporte articulos del almacen {{nombreAlmacen}}
</h3>


<table class="table table-hover">
    <thead>
        <tr>
            <th>Detalle</th>
            <th>Nombre</th>
            <th>Sucursal</th>
        </tr>
    </thead>
    <tbody *ngIf="articulos.length > 0">
        <tr *ngFor="let item of articulos">
            <td>{{item.detalle}}</td>
            <td>{{item.nombre_articulo}}</td>
            <td>{{item.sucursal_nombre}}</td>
        </tr>
    </tbody>
    <tbody *ngIf="articulos.length == 0">
        <tr>
            <td colspan="3" class="text-center">
                No hay registros
            </td>
        </tr>
    </tbody>
</table>

