import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DataResponse } from '../models/response/data-response';
import { Observable } from 'rxjs';

const httpHeaders = {
  headers: new HttpHeaders(environment.apiConfig.headersFormData),
  reportProgress: true
}

const path = environment.apiConfig.path;

@Injectable({
  providedIn: 'root'
})
export class CategoriaService {

  constructor(private httpClient: HttpClient) { }

  public getAll(): Observable<DataResponse> {
    return this.httpClient.get<DataResponse>(`${path}/categorias`, httpHeaders);
  }
}
